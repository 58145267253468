import {
  faListOl,
  faQuestion,
  faSkiing,
  faSkiingNordic,
  faSnowboarding,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const getGearIcon = (type) => {
  if (type && type.includes("Multihyr")) {
    return <FontAwesomeIcon icon={faListOl} />;
  } else if ((type && type.includes("Alpint")) || (type && type === "Blades")) {
    return <FontAwesomeIcon icon={faSkiing} />;
  } else if (type && type.includes("Snowboard")) {
    return <FontAwesomeIcon icon={faSnowboarding} />;
  } else if (type && type.includes("Längd")) {
    return <FontAwesomeIcon icon={faSkiingNordic} />;
  }

  return <FontAwesomeIcon icon={faQuestion} />;
};

export const gearTypes = [
  { value: "", text: "-- Välj typ --", id: "" },
  {
    value: "Alpint - Nybörjare",
    text: "Alpint - Nybörjare",
    id: "downhillBeginner",
  },
  {
    value: "Alpint - Mellan/Twintips",
    text: "Alpint - Mellan/Twintips",
    id: "downhillTwintips",
  },
  { value: "Alpint - Topp", text: "Alpint - Topp", id: "downhillTop" },
  {
    value: "Alpint - Avancerad",
    text: "Alpint - Avancerad",
    id: "downhillAdvanced",
  },
  { value: "Alpint - Ungdom", text: "Alpint - Ungdom", id: "downhillYouth" },
  { value: "Alpint - Barn", text: "Alpint - Barn", id: "downhillKid" },
  { value: "Alpint - Blades", text: "Alpint - Blades", id: "downhillBlades" },
  {
    value: "Snowboard - Vuxen",
    text: "Snowboard - Vuxen",
    id: "snowboardAdult",
  },
  {
    value: "Snowboard - Ungdom",
    text: "Snowboard - Ungdom",
    id: "snowboardYouth",
  },
  { value: "Snowboard - Barn", text: "Snowboard - Barn", id: "snowboardKid" },
  {
    value: "Längd (skins) - Vuxen",
    text: "Längd (skins) - Vuxen",
    id: "nordicAdult",
  },
  {
    value: "Längd (skins) - Ungdom",
    text: "Längd (skins) - Ungdom",
    id: "nordicYouth",
  },
  {
    value: "Längd (skins) - Barn",
    text: "Längd (skins) - Barn",
    id: "nordicChild",
  },
  {
    value: "Längd - Turskidor/Back Country",
    text: "Langd - Turskidor/Back Country",
    id: "nordicBackCountry",
  },
  { value: "Längd - Fjällpulka", text: "Längd - Fjällpulka", id: "nordicSled" },
  { value: "Multihyr - Vuxen", text: "Multihyr - Vuxen", id: "multiAdult" },
  { value: "Multihyr - Ungdom", text: "Multihyr - Ungdom", id: "multiYouth" },
];
