import * as yup from "yup";

export default yup.object({
  name: yup.string().required("Namn måste anges").max(100),
  socialSecurity: yup
    .string()
    .required("Personnummer måste anges")
    .matches(/^\d+$/, "Kan endast bestå av siffror")
    .min(12, "Personnummer med 12 siffror i formatet YYYYMMDDXXXX")
    .max(12, "Personnummer med 12 siffror i formatet YYYYMMDDXXXX"),
  address: yup.string().required("Adress måste anges"),
  zipCode: yup.string().required("Postnummer måste anges"),
  city: yup.string().required("Ort måste anges"),
  phone: yup.string().required("Telefonnummer måste anges"),
  email: yup
    .string()
    .email("Vänligen skriv en korrekt e-post")
    .required("E-post måste anges"),
  startDate: yup.date().required(),
  endDate: yup.date().required(),
  users: yup
    .array()
    .min(1, "Det måste finnas minst 1 användare")
    .required("Det måste finnas minst 1 användare")
    .of(
      yup.object().shape({
        name: yup.string().required("Namn måste anges"),
        age: yup
          .string()
          .matches(/^\d+$/, "Kan endast bestå av siffror")
          .required("Ålder måste anges")
          .min(1),
        weight: yup
          .string()
          .matches(/^\d+$/, "Kan endast bestå av siffror")
          .required("Vikt måste anges"),
        length: yup
          .string()
          .matches(/^\d+$/, "Kan endast bestå av siffror")
          .required("Längd måste anges"),
        shoeSize: yup
          .string()
          .matches(/^\d+$/, "Kan endast bestå av siffror")
          .required("Skostorlek måste anges"),
        gearType: yup.string().required("Utrustningstyp måste anges"),
      })
    ),
});
