import React from "react";

const CustomInput = ({
  label,
  name,
  register,
  placeholder = null,
  errors,
  className,
  type = "text",
  onChange,
  options = null,
  valueKey = null,
  textKey = null,
  arrayName = null,
  arrayIndex = null,
  arrayProperty = null,
}) => {
  const getErrors = () => {
    if (arrayName) {
      return (
        errors &&
        errors[arrayName] &&
        errors[arrayName][arrayIndex] &&
        errors[arrayName][arrayIndex][arrayProperty]
      );
    }

    return errors && errors[name];
  };

  return (
    <div className={`mt-4 ${className}`}>
      <label className="block text-sm font-semibold" htmlFor={name}>
        {label}
      </label>
      {type === "text" && (
        <input
          placeholder={placeholder}
          className="w-full placeholder-gray-400 border-gray-400 rounded"
          type="text"
          {...register(name)}
        />
      )}

      {type === "select" && options && (
        <select
          className="w-full border-gray-400 rounded"
          {...register(name)}
          onChange={onChange}
        >
          {options.map((option, index) => (
            <option value={option[valueKey]} key={index}>
              {option[textKey]}
            </option>
          ))}
        </select>
      )}
      {getErrors() && <p className="text-red-400">{getErrors().message}</p>}
    </div>
  );
};

export default CustomInput;
