import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const Confirmation = ({ bookingId, email }) => {
  return (
    <div className="px-4 mt-16 ">
      <div className="flex flex-col items-center w-full p-8 text-gray-700 bg-gray-100 border border-gray-200 rounded-lg">
        <FontAwesomeIcon
          icon={faCheckCircle}
          className="mx-auto text-6xl text-center text-red-800"
        />
        <h2 className="mt-8 text-3xl font-bold text-center font-display">
          Tack för din bokning!
        </h2>
        <p className="mt-4 text-xl text-center text-display">
          Bokningsnummer: <span className="uppercase">{bookingId}</span>
        </p>
        <p className="mt-4 text-center text-gray-500">
          En bokningsbekräftelse kommer att skickas till{" "}
          <span className="font-medium">{email}</span>
        </p>
      </div>
    </div>
  );
};

export default Confirmation;
