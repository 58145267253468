import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const Header = ({ title, number, open, setOpen }) => {
  return (
    <div
      className="flex items-center w-full mt-16 text-xl font-semibold bg-gray-100 rounded cursor-pointer font-display hover:bg-gray-200"
      onClick={() => setOpen(!open)}
    >
      <span className="h-full px-3 py-3 text-white bg-red-800 rounded-l">
        {number}.
      </span>
      <div className="flex items-center w-full px-3">
        <h2 className="py-3">{title}</h2>

        <FontAwesomeIcon
          className={`ml-auto ${open && "transform rotate-180"}`}
          icon={faChevronDown}
        />
      </div>
    </div>
  );
};

export default Header;
