import Calendar from "react-calendar";
import { useState, useEffect } from "react";
import "react-calendar/dist/Calendar.css";
import CustomInput from "./components/CustomInput";
import { useFieldArray, useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faRedoAlt, faSearch } from "@fortawesome/free-solid-svg-icons";
import Preview from "./components/Preview";
import FormSection from "./components/FormSection";
import PageHeader from "./components/PageHeader";
import { yupResolver } from "@hookform/resolvers/yup";
import Confirmation from "./components/Confirmation";
import schema from "./utils/form-schema";
import User from "./components/User";
import { getRebate } from "./utils/price-helpers";

function App() {
  const [value, onChange] = useState(null);
  const [preview, setPreview] = useState(false);
  const [formValue, setFormValue] = useState(null);
  const [bookingId, setBookingId] = useState(null);
  const [prices, setPrices] = useState(null);
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm({ resolver: yupResolver(schema) });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "users",
  });

  var tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  const addUser = () => {
    append({
      name: "",
      age: "",
      shoeSize: "",
      length: "",
      weight: "",
      gearType: "",
    });
  };

  const getPrices = async () => {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/prices`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    const priceData = await res.json();

    let priceList = [];

    priceData.prices.forEach((data) => {
      if (data.area !== "workshop") {
        priceList = [...priceList, ...data.contents[0].entries];
      }
    });

    setPrices(priceList);
  };

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const { startDate, endDate } = Object.fromEntries(
      urlSearchParams.entries()
    );

    if (startDate && endDate) {
      onChange([new Date(startDate), new Date(endDate)]);
      setValue("startDate", startDate);
      setValue("endDate", endDate);
    }

    getPrices();

    addUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (data) => {
    setFormValue(data);
    setPreview(true);
  };

  const setMaxDate = () => {
    const startDate = value && value[0];
    const endDate = value && value[1];

    if (startDate && !endDate) {
      var result = new Date(startDate);
      result.setDate(result.getDate() + 7);
      return result;
    }

    return null;
  };

  const handleDateChange = (range) => {
    onChange(range);
    setValue("startDate", range[0]);
    setValue("endDate", range[1]);
  };

  const getTotalPrice = () => {
    let totalPrice = 0;
    watch().users.forEach((user) => {
      if (user.price && user.price > 0) {
        totalPrice += user.price;
      }
    });

    return totalPrice;
  };

  return (
    <div className="w-full font-body">
      <div className="flex flex-col w-full max-w-screen-md px-4 mx-auto mb-20">
        {!bookingId && (
          <>
            <PageHeader />
            {!preview && (
              <>
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className="text-gray-600"
                >
                  <FormSection title="Bokningsperiod" number="1">
                    <div className="relative px-4">
                      <button
                        type="button"
                        className="absolute items-center font-bold text-red-800 flext right-8 top-4"
                        onClick={() => handleDateChange([null, null])}
                      >
                        <FontAwesomeIcon className="mr-2" icon={faRedoAlt} />
                        Återställ
                      </button>
                      <Calendar
                        onChange={(range) => {
                          handleDateChange(range);
                        }}
                        value={value}
                        minDate={tomorrow}
                        maxDate={setMaxDate()}
                        selectRange
                        allowPartialRange
                        minDetail="month"
                        className="mt-8"
                      />
                      {errors && (errors["startDate"] || errors["endDate"]) && (
                        <p className="mt-2 text-red-400">
                          Bokningsperiod måste anges
                        </p>
                      )}
                      <CustomInput
                        className="mt-8"
                        label="Ungefärlig ankomsttid"
                        name="estimatedArrival"
                        errors={errors}
                        register={register}
                      />
                    </div>
                  </FormSection>
                  <FormSection title="Personuppgifter" number="2">
                    <div className="flex flex-col px-4 mt-4">
                      <CustomInput
                        label="Namn"
                        name="name"
                        errors={errors}
                        register={register}
                      />
                      <CustomInput
                        label="Personnummer"
                        name="socialSecurity"
                        placeholder="YYYYMMDDXXXX"
                        errors={errors}
                        register={register}
                      />
                      <CustomInput
                        label="Adress"
                        name="address"
                        errors={errors}
                        register={register}
                      />
                      <div className="flex items-center space-x-4">
                        <CustomInput
                          className="flex-1"
                          label="Postnummer"
                          name="zipCode"
                          errors={errors}
                          register={register}
                        />
                        <CustomInput
                          className="flex-2"
                          label="Ort"
                          name="city"
                          errors={errors}
                          register={register}
                        />
                      </div>
                      <CustomInput
                        label="Telefonnummer"
                        name="phone"
                        errors={errors}
                        register={register}
                      />
                      <CustomInput
                        label="E-post"
                        name="email"
                        errors={errors}
                        register={register}
                      />
                    </div>
                  </FormSection>
                  <FormSection title="Användare" number="3">
                    <div className="flex flex-col px-4">
                      {fields.map((user, userIndex) => (
                        <User
                          key={user.id}
                          prices={prices}
                          userIndex={userIndex}
                          watch={watch}
                          register={register}
                          remove={remove}
                          startDate={watch().startDate}
                          endDate={watch().endDate}
                          setValue={setValue}
                        />
                      ))}
                      {errors && errors["users"] && errors["users"].message && (
                        <p className="mt-2 text-red-400">
                          {errors["users"].message}
                        </p>
                      )}
                      <button
                        onClick={addUser}
                        type="button"
                        className="flex items-center px-4 py-2 mt-4 ml-auto font-semibold text-red-800 bg-transparent border border-red-800 rounded font-display"
                      >
                        <FontAwesomeIcon icon={faPlus} className="mr-2" />
                        Lägg till användare
                      </button>
                    </div>
                  </FormSection>
                  <button
                    type="submit"
                    className="flex items-center px-4 py-2 mt-16 ml-auto text-lg font-semibold text-white bg-red-800 rounded-lg font-display"
                  >
                    <FontAwesomeIcon icon={faSearch} className="mr-3" />
                    Förhandsgranska
                  </button>
                </form>
              </>
            )}
            {preview && (
              <Preview
                data={formValue}
                goBack={() => setPreview(false)}
                setConfirmation={(id) => {
                  setBookingId(id);
                  setPreview(false);
                }}
              />
            )}
          </>
        )}
        {bookingId && (
          <Confirmation bookingId={bookingId} email={formValue?.email} />
        )}
      </div>
      {!preview &&
        !bookingId &&
        watch().users &&
        watch().users.some((x) => x.price && x.price > 0) && (
          <div className="fixed bottom-0 flex w-full text-white bg-gray-800">
            <div className="flex w-full max-w-screen-md px-8 py-4 mx-auto text-xl text-right font-display">
              <h4 className="ml-auto mr-2">Totalt Pris:</h4>
              <span className="mr-2 font-thin line-through">
                {getTotalPrice()} SEK
              </span>
              <span className="font-semibold">
                {(getTotalPrice() * getRebate(watch().startDate)).toFixed()} SEK
              </span>
            </div>
          </div>
        )}
    </div>
  );
}

export default App;
