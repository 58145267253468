import React from "react";
import logo from "../images/logo.png";
const PageHeader = () => {
  return (
    <header className="flex flex-col items-center justify-center w-full mt-8 text-center font-display">
      <img src={logo} alt="Logo" />
      <h1 className="my-4 text-2xl font-bold text-center">Skapa Bokning</h1>
      <p>
        <span className="font-bold text-red-800">20%</span> rabatt gäller om ni
        bokar minst 7 dagar innan första uthyrningsdag.
      </p>
      <p className="mt-2">
        <span className="font-bold text-red-800">10%</span> rabatt Gäller om ni
        bokar senare än 7 dagar innan första uthyrningsdag.
      </p>
    </header>
  );
};

export default PageHeader;
