import React, { useState } from "react";
import Header from "./Header";

const FormSection = ({ children, title, number }) => {
  const [open, setOpen] = useState(true);
  return (
    <div>
      <Header title={title} number={number} open={open} setOpen={setOpen} />

      {open && children}
    </div>
  );
};

export default FormSection;
