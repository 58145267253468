import { getDaysBetween } from "./date-helpers";

export const getPrice = (id, start, end, prices) => {
  const startDate = new Date(start);
  const endDate = new Date(end);
  startDate.setHours(0);
  startDate.setMinutes(0);
  endDate.setHours(23);
  endDate.setMinutes(59);
  const days = getDaysBetween(new Date(startDate), new Date(endDate));
  const index = getDayIndex(days);
  const priceData = prices.find((x) => x.id === id);

  return priceData.prices[index] ?? 0;
};

export const getRebate = (startDate) => {
  const days = getDaysBetween(new Date(), new Date(startDate));
  return days >= 7 ? 0.8 : 0.9;
};

const getDayIndex = (days) => {
  if (days < 6) return days - 1;

  return 5;
};
