import {
  faCheck,
  faChevronDown,
  faChevronLeft,
  faExclamationCircle,
  faRulerVertical,
  faShoePrints,
  faTimes,
  faWeight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { getGearIcon } from "../utils/gear-helpers";
import { getRebate } from "../utils/price-helpers";

const Preview = ({ goBack, data, setConfirmation }) => {
  const [isUsersOpen, setisUsersOpen] = useState(true);
  const [error, setError] = useState(false);
  const [validationError, setValidationError] = useState(null);
  const [accepted, setAccepted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getServerDate = (date) => {
    const offset = date.getTimezoneOffset();
    date = new Date(date.getTime() - offset * 60 * 1000);
    return date.toISOString().split("T")[0];
  };

  const getTotalPrice = () => {
    let totalPrice = 0;
    data.users.forEach((user) => {
      if (user.price) {
        totalPrice += user.price;
      }
    });

    return totalPrice;
  };

  const createBooking = async () => {
    if (!accepted) {
      setValidationError("Hyresvillkoren har ej godkänts");
      return;
    } else {
      setValidationError(null);
    }

    setIsLoading(true);

    try {
      const parsedData = {
        ...data,
        startDate: getServerDate(data.startDate),
        endDate: getServerDate(data.endDate),
      };

      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/websitebooking`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(parsedData),
        }
      );

      const { bookingId } = await res.json();

      setIsLoading(false);
      setConfirmation(bookingId);
    } catch (error) {
      console.log(error);
      setError(true);
      setIsLoading(false);
    }
  };

  return (
    <>
      {error && (
        <div className="flex w-full h-auto mt-8 bg-gray-100 rounded oveflow-hidden">
          <div className="flex items-center p-4 border-l-4 border-red-800 rounded-l">
            <FontAwesomeIcon
              className="mr-4 text-3xl text-red-800"
              icon={faExclamationCircle}
            />
            <div className="flex flex-col">
              <h3 className="text-lg font-bold leading-none font-display">
                Kunde inte skapa bokning!
              </h3>
              <span className="text-gray-500">
                Försök igen senare eller kontakta oss på bokning@goranshc.se
              </span>
            </div>
          </div>

          <FontAwesomeIcon
            className="mt-4 ml-auto mr-4 text-gray-500 cursor-pointer opacity-80 hover:opacity-100"
            icon={faTimes}
            onClick={() => setError(null)}
          />
        </div>
      )}
      {isLoading && (
        <div className="flex flex-col items-center justify-center w-full h-96">
          <svg
            className="w-12 h-12 mr-3 -ml-1 text-red-800 animate-spin"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
          <h3 className="mt-3 text-xl text-red-800 font-display">
            Skapar bokning...
          </h3>
        </div>
      )}
      {!isLoading && (
        <div className="flex flex-col h-full">
          <div className="flex items-baseline justify-between mt-16 border-b-4 border-gray">
            <h2 className="inline-block pb-4 -mb-1 text-lg font-bold uppercase border-b-4 border-red-800 font-display">
              Bokningsperiod
            </h2>
          </div>
          <div className="flex mt-4 text-2xl font-light">
            {`${data.startDate.toLocaleDateString()} - ${data.endDate.toLocaleDateString()}`}
          </div>
          <div className="flex items-baseline justify-between mt-16 border-b-4 border-gray">
            <h2 className="inline-block pb-4 -mb-1 text-lg font-bold uppercase border-b-4 border-red-800 font-display">
              Personuppgifter
            </h2>
          </div>
          <div className="flex flex-col px-4">
            <span className="mt-4 text-xl font-medium">{data.name}</span>
            <span className="text-lg font-light text-gray-600">
              {data.socialSecurity
                .replace(/(.{4})/g, "$1-")
                .substr(0, data.socialSecurity.length + 2)}
            </span>

            <div className="flex flex-col mt-4">
              <span className="font-bold">Adress</span>
              <span>{data.address}</span>
              <span>
                {data.zipCode} {data.city}
              </span>
            </div>
            <div className="flex flex-col mt-4">
              <span className="font-bold">E-post</span>
              <span>{data.email}</span>
            </div>

            <div className="flex flex-col mt-4">
              <span className="font-bold">Telefonnummer</span>
              <span>{data.phone}</span>
            </div>
          </div>
          <div className="flex items-baseline justify-between mt-16 border-b-4 border-gray">
            <h2 className="inline-block pb-4 -mb-1 text-lg font-bold uppercase border-b-4 border-red-800 font-display">
              Användare
            </h2>
            <FontAwesomeIcon
              onClick={() => setisUsersOpen(!isUsersOpen)}
              className={`ml-2 cursor-pointer transition-transform transform ${
                isUsersOpen && "rotate-180"
              }`}
              icon={faChevronDown}
            />
          </div>
          {isUsersOpen && (
            <div className="flex flex-col">
              {data.users.map((user, index) => (
                <div className="flex w-full mt-8" key={index}>
                  <div className="flex items-center justify-center w-10 h-10 text-white bg-red-800 rounded-full">
                    {getGearIcon(user.gearType)}
                  </div>
                  <div className="flex flex-col w-full ml-4">
                    <div className="flex flex-col md:flex-row">
                      <h4 className="flex-1 text-xl font-bold">
                        {user.name || `Användare ${index + 1}`}
                      </h4>
                      <div className="flex flex-1 md:flex-col">
                        <span className="line-through">{user.price} SEK</span>
                        <span className="ml-2 md:ml-0">
                          {(user.price * getRebate(data.startDate)).toFixed()}{" "}
                          SEK
                        </span>
                      </div>
                    </div>
                    <div className="flex text-lg text-gray-500">
                      {user.age && <span className="mr-4">{user.age} år</span>}
                      {user.gearType && <span>{user.gearType}</span>}
                    </div>
                    <div>
                      {user.length && (
                        <span>
                          <FontAwesomeIcon
                            className="mr-2 text-red-800"
                            icon={faRulerVertical}
                          />
                          {user.length}
                        </span>
                      )}
                      {user.weight && (
                        <span>
                          <FontAwesomeIcon
                            className="ml-2 mr-2 text-red-800"
                            icon={faWeight}
                          />
                          {user.weight}
                        </span>
                      )}
                      {user.shoeSize && (
                        <span>
                          <FontAwesomeIcon
                            className="ml-2 mr-2 text-red-800"
                            icon={faShoePrints}
                          />
                          {user.shoeSize}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              ))}
              <div className="flex flex-col items-end w-full py-2 mt-8 text-xl border-t border-gray-300">
                <span>
                  Ordinarie pris:{" "}
                  <span className="ml-2 line-through">{getTotalPrice()}</span>
                </span>
                <span className="font-semibold">
                  Totalt pris:{" "}
                  <span className="ml-2">
                    {(getTotalPrice() * getRebate(data.startDate)).toFixed()}
                  </span>
                </span>
              </div>
            </div>
          )}
          <div className="flex flex-col">
            <div className="flex items-baseline justify-between mt-16 border-b-4 border-gray">
              <h2 className="inline-block pb-4 -mb-1 text-lg font-bold uppercase border-b-4 border-red-800 font-display">
                Hyresvillkor
              </h2>
            </div>
            <p className="mt-4">
              För att hyra utrustning hos oss måste du lämna dina fullständiga
              personuppgifter och kunna identifiera dig med giltig
              fotolegitimation på plats. Alla personuppgifter behandlas i
              enlighet med Personuppgiftslagen (PuL). Hyrestagaren är fullt
              ansvarig för utrustningen. Vid skada eller stöld ersätts
              utrustningen helt av hyrestagaren som debiteras fullvärde. Den som
              ansvarar för egen utrustning måste vara minst 18 år.
            </p>
            <div className="flex items-center mt-4">
              <input
                type="checkbox"
                name="termsAccepted"
                value={accepted}
                onChange={() => setAccepted(!accepted)}
                className="text-red-800 border-gray-300 rounded shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              />
              <span className="ml-2 font-medium ">
                Jag har godkänt hyresvillkoren.
              </span>
            </div>
            {validationError && (
              <span className="text-red-400">{validationError}</span>
            )}
          </div>
          <div className="flex justify-end w-full mt-auto">
            <button
              type="button"
              className="flex items-center my-8 font-medium text-red-800"
              onClick={() => goBack()}
            >
              <FontAwesomeIcon className="mr-2" icon={faChevronLeft} />
              Tillbaka
            </button>
            <button
              type="button"
              onClick={createBooking}
              className="flex items-center px-4 py-2 my-8 ml-4 font-medium text-white bg-red-800 rounded-lg"
            >
              <FontAwesomeIcon className="mr-2" icon={faCheck} />
              Boka
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Preview;
